import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'
import { Navigation, Thumbs } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

// connects with `data-controller='aon--lightbox-gallery-component'`
export default class extends Controller {
  static targets = ['dialog', 'mainSwiper', 'thumbSwiper', 'closeButton']

  connect () {
    this.prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)'
    ).matches
    this.mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    this.mediaQuery.addEventListener(
      'change',
      this.handleReducedMotionChange.bind(this)
    )

    document.addEventListener(
      'imageCarousel:imageClick',
      this.handleImageClick.bind(this)
    )

    this.clickedIndex = 0

    this.element.addEventListener('sl-after-hide', this.close.bind(this))
  }

  disconnect () {
    document.removeEventListener(
      'imageCarousel:imageClick',
      this.handleImageClick.bind(this)
    )
    this.mediaQuery.removeEventListener(
      'change',
      this.handleReducedMotionChange.bind(this)
    )
    this.element.removeEventListener('sl-after-hide', this.close.bind(this))
    this.destroySwipers()
  }

  handleImageClick (event) {
    const { slideIndex } = event.detail
    this.clickedIndex = slideIndex

    event.preventDefault()

    this.dialogTarget.show()
  }

  initLightbox () {
    this.initializeSwipers(this.clickedIndex)
  }

  initializeSwipers (initialIndex = 0) {
    const speed = this.prefersReducedMotion ? 0 : 300

    this.thumbSwiper = new Swiper(this.thumbSwiperTarget, {
      slidesPerView: 'auto',
      spaceBetween: 10,
      centerInsufficientSlides: true,
      slideToClickedSlide: true,
      watchSlidesProgress: true,
      speed
    })

    this.mainSwiper = new Swiper(this.mainSwiperTarget, {
      slidesPerView: 1,
      spaceBetween: 100,
      centeredSlides: true,
      modules: [Navigation, Thumbs],
      autoHeight: false,
      preventClicks: true,
      speed,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      thumbs: {
        swiper: this.thumbSwiper
      },
      initialSlide: initialIndex
    })

    this.thumbSwiper.slideTo(initialIndex)
  }

  destroySwipers () {
    if (this.mainSwiper) {
      this.mainSwiper.destroy()
      this.mainSwiper = null
    }
    if (this.thumbSwiper) {
      this.thumbSwiper.destroy()
      this.thumbSwiper = null
    }
  }

  close () {
    this.destroySwipers()
  }

  handleReducedMotionChange (event) {
    this.prefersReducedMotion = event.matches
    if (this.mainSwiper) {
      this.mainSwiper.params.speed = this.prefersReducedMotion ? 0 : 300
      this.thumbSwiper.params.speed = this.prefersReducedMotion ? 0 : 300
    }
  }
}
