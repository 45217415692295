import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["drawer", "allEditors"];

  connect() {
    this.systemDrawer = document.getElementById("system-drawer");
  }

  openDrawer(event) {
    this.systemDrawer.label = "Edited By";
    this.systemDrawer.querySelector(
      ".drawer-content"
    ).innerHTML = this.allEditorsTarget.innerHTML;

    if (document.querySelector("html").clientWidth < 768) {
      // mobile
      this.systemDrawer.placement = "bottom";
    } else {
      // desktop
      this.systemDrawer.placement = "end";
    }

    this.systemDrawer.show();
  }
}
