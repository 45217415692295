import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  escapeHtml(html) {
    const div = document.createElement('div');
    div.textContent = html;
    return div.innerHTML;
  }

  toast(event) {
    const { heading, message, variant, duration = 10000 } = event.detail;
    const icons = {
      primary: 'fa-light fa-circle-info',
      success: 'fa-light fa-circle-check',
      warning: 'fa-light fa-circle-exclamation',
      danger: 'fa-light fa-triangle-exclamation',
      default: 'fa-light fa-circle-info',
    };
    const icon_classes = icons[variant] || icons.default;

    const alert = Object.assign(document.createElement('sl-alert'), {
      variant,
      closable: true,
      duration,
      classList: 'aon--alert-component',
      innerHTML: `
            <i slot="icon" class="${icon_classes}"></i>
            <div class="aon--alert-copy">
              <span class="text-gray-900 aon-subtitle-smallest font-bold">${this.escapeHtml(
                heading
              )}</span>
              <p class="aon--alert-subtitle text-gray-800 aon-subtitle-smallest">${this.escapeHtml(
                message
              )}</p>
            </div>
        `,
    });

    document.body.append(alert);
    return alert.toast();
  }
}
