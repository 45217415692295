import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hasDoneCount', 'wantsToCount', 'listCount']

  incrementCount (e) {
    if (e.detail.kind == "has-done") {
      this.hasDoneCountTarget.value++
    } else if (e.detail.kind == "wants-to") {
      this.wantsToCountTarget.value++
    }
  }

  decrementCount (e) {
    if (e.detail.kind == "has-done") {
      this.hasDoneCountTarget.value--
    } else if (e.detail.kind == "wants-to") {
      this.wantsToCountTarget.value--
    }
  }

  updateListCount (e) {
    if (e.detail.checked) {
      this.listCountTarget.value++
    } else {
      this.listCountTarget.value--
    }
  }
}