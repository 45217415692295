import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['poster', 'video']

  async playVideo () {
    try {
      this.posterTarget.classList.add('hidden')
      this.videoTarget.classList.remove('hidden')

      const playPromise = this.videoTarget.play()
      if (playPromise !== undefined) {
        await playPromise
      }
    } catch (error) {
      console.error('Error playing video:', error)
      this.posterTarget.classList.remove('hidden')
      this.videoTarget.classList.add('hidden')
    }
  }

  videoEnded () {
    this.posterTarget.classList.remove('hidden')
    this.videoTarget.classList.add('hidden')
  }
}
