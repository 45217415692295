import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
export default class extends Controller {
  static values = {
    marked: Boolean,
    markId: String,
    resourceSgid: String,
    resourceId: String,
    resourceType: String,
    kind: String,
    source: String
  }

  connect () {
    this.element['activityStreamButton'] = this
    this.refreshButton()
  }

  refreshButton () {
    if (this.markedValue) {
      this.element.classList.add('marked')
    } else {
      this.element.classList.remove('marked')
    }
  }

  redirectIfGuest (event) {
    const userSignedIn = Cookies.get('user_signed_in') === 'true'

    if (!userSignedIn) {
      event.preventDefault()
      event.stopPropagation()
      window.location.href = '/join'
    }
  }

  markedValueChanged () {
    this.refreshButton()
  }

  handleToggled (event) {
    if (this.element.classList.contains('disabled')) {
      event.preventDefault()
      event.stopPropagation()
      return
    }

    const details = {
      resourceSgid: this.resourceSgidValue,
      source: this.element,
      kind: this.kindValue
    }

    this.markedValue = event.detail.active

    if (this.markedValue) {
      this.dispatch('marked', {
        detail: details,
        prefix: 'activity-streams--button'
      })
      this.dispatch('activityButtonToggled', {
        detail: { marked: this.markedValue, kind: this.kindValue, source: this.sourceValue },
        prefix: 'aon-event'
      })
    } else {
      this.dispatch('unmarked', {
        detail: details,
        prefix: 'activity-streams--button'
      })
      this.dispatch('activityButtonToggled', {
        detail: { marked: this.markedValue, kind: this.kindValue, source: this.sourceValue },
        prefix: 'aon-event'
      })
    }
  }

  set disabled (value) {
    if (value === true) {
      this.element.classList.add('disabled')
    } else {
      this.element.classList.remove('disabled')
    }
  }

  set marked (value) {
    let newValue = value.toString().toLowerCase()
    if (newValue === this.markedValue.toString().toLowerCase()) return

    this.markedValue = newValue === 'false' ? false : true

    this.element.querySelector(
      '.aon-toggle-button'
    ).toggleButton.active = this.markedValue
  }

  get marked () {
    return this.markedValue
  }
}
