import { Controller } from '@hotwired/stimulus'

// USAGE:
// The analytics controller is initialized on the body in the AON application layout.
// To send a custom event, set `data-action="analytics#sendEvent"` on the element, along with
// `data-ga-event-action`, `data-ga-event-category`, and `data-ga-event-label`
//
// alternately...
//
// From javascript, dispatch an `analytics:userEvent` event with a `detail` object containing
// a `handler` key and a `data` key. The `handler` value should be the name of a function on this
// controller, and the `data` value should be an object containing any data you want to pass to the
// handler function.
// e.g.
//   this.dispatch('userEvent', {
//     prefix: 'analytics',
//     detail: {
//       handler: 'handleAtlasListAction',
//       data: { marked: true }
//     }
//   })
export default class extends Controller {
  connect () {
    this.boundAnalyticsEventListener = this.analyticsEventListener.bind(this)
    document.addEventListener(
      'analytics:userEvent',
      this.boundAnalyticsEventListener
    )
  }

  disconnect () {
    document.removeEventListener(
      'analytics:userEvent',
      this.boundAnalyticsEventListener
    )
  }

  analyticsEventListener (event) {
    let handler = this[event.detail.handler]

    if (typeof handler === 'function')
      handler.call(this, { detail: event.detail.data })
  }

  sendEvent (event) {
    this.sendGtagEvent(event.currentTarget.dataset.gaEventAction, {
      event_category: event.currentTarget.dataset.gaEventCategory,
      event_label: event.currentTarget.dataset.gaEventLabel
    })
  }

  handleBasicGaEvent ({ detail: { gaAction, gaCategory, gaLabel } }) {
    this.sendGtagEvent(gaAction, {
      event_category: gaCategory,
      event_label: gaLabel
    })
  }

  handleAtlasAction ({ detail: { marked, kind, source } }) {
    const eventActions = {
      'has-done': "I've Been Here",
      'wants-to': 'I Want to Go Here'
    }

    const eventAction =
      marked == true ? eventActions[kind] : `Deleted ${eventActions[kind]}`

    this.sendGtagEvent(eventAction, {
      event_category: 'Atlas Action',
      event_label: source
    })
  }

  handleAtlasListAction ({ detail: { marked, source } }) {
    const eventAction = marked == true ? 'Added to List' : 'Removed from List'

    this.sendGtagEvent(eventAction, {
      event_category: 'Atlas Action',
      event_label: source
    })
  }

  handleAtlasListCreateAction () {
    this.sendGtagEvent('Created List and Added', {
      event_category: 'Atlas Action'
    })
  }

  sendGtagEvent (action, opts = {}) {
    if (opts['event_label'] === '@PATH@')
      opts['event_label'] = window.location.pathname

    if (process.env.NODE_ENV === 'development')
      console.log('sendGtagEvent: ', action, opts)

    gtag('event', action, opts)
  }
}
