import { Controller } from '@hotwired/stimulus'
import { MAP_MARKERS } from '../../javascript/constants/map_markers'
import { useHotkeys } from 'stimulus-use/hotkeys'

let mapKitInitialized = null

// connects with `data-controller='aon--place-map-component'`
export default class extends Controller {
  static values = {
    placeId: Number,
    latitude: Number,
    longitude: Number,
    isGastro: Boolean,
    title: String,
    address: String,
    url: String,
    moreUrl: String,
    page: { type: Number, default: 1 }
  }

  static targets = [
    'map',
    'buttonContainer',
    'maximizeButton',
    'minimizeButton',
    'showMoreContainer',
    'showMoreButton',
    'calloutTemplate'
  ]

  static classes = ['open', 'closed']

  static ZOOM_LEVELS = {
    TIGHT: {
      MAX_DISTANCE: 0.01, // ~1.1 km
      SPAN: 0.02
    },
    CLOSE: {
      MAX_DISTANCE: 0.05, // ~5.5 km
      SPAN: 0.1
    },
    MEDIUM: {
      MAX_DISTANCE: 0.2, // ~22 km
      SPAN: 0.4
    },
    FAR: {
      SPAN: 0.8
    }
  }

  static MARKER_DROP_SHADOW = 'drop-shadow(rgba(0, 0, 0, 0.3) 0px 1px 2px)'
  static MARKER_WIDTH = '22px'
  static MARKER_HEIGHT = '32px'

  static PIN_WIDTH = '20px'
  static PIN_HEIGHT = '32px'

  async connect () {
    const response = await fetch('/aon/map/token', {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain'
      }
    })
    this.mapTokenValue = await response.text()
    await this.setupMapKit()
    this.observeMapVisibility()
    this.savedMaximizedRegion = null
    useHotkeys(this, {
      esc: [this.escapeHandler]
    })
  }

  disconnect () {
    if (this.map) {
      this.map.destroy()
      this.map = null
    }
  }

  observeMapVisibility () {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this.initializeMap()
        observer.disconnect()
      }
    })
    observer.observe(this.mapTarget)
  }

  async setupMapKit () {
    if (!mapKitInitialized) {
      mapKitInitialized = new Promise((resolve, reject) => {
        const maxAttempts = 50 // 5 seconds total
        let attempts = 0

        const waitForMapKit = () => {
          if (typeof mapkit !== 'undefined') {
            mapkit.init({
              authorizationCallback: done => {
                done(this.mapTokenValue)
                resolve()
              }
            })
          } else if (attempts < maxAttempts) {
            attempts++
            setTimeout(waitForMapKit, 100)
          } else {
            console.warn('MapKit failed to load after 5 seconds')
            reject(new Error('MapKit failed to load'))
          }
        }
        waitForMapKit()
      })
    }

    try {
      await mapKitInitialized
    } catch (error) {
      console.error('Error initializing MapKit:', error)
      // Optionally show a user-friendly error message
      // this.element.innerHTML = 'Map temporarily unavailable'
    }
  }

  async initializeMap () {
    if (this.map) return // Map already initialized

    try {
      const coordinate = this.primaryCoordinate

      // Fetch first page of nearby places to determine zoom level
      let span = new mapkit.CoordinateSpan(0.05, 0.05) // Default zoom level

      if (this.moreUrlValue) {
        try {
          const response = await fetch(this.moreUrlValue + '?page=1')
          const data = await response.json()
          span = this.calculateZoomSpan(data.places, coordinate)
        } catch (error) {
          console.error('Error fetching nearby places for zoom:', error)
        }
      }

      // Store the initial span for minimizing later
      this.initialSpan = span

      // Initialize map with custom options
      this.map = new mapkit.Map(this.mapTarget, {
        center: coordinate,
        showsZoomControl: false,
        showsMapTypeControl: false,
        isScrollEnabled: false,
        isRotationEnabled: false,
        isZoomEnabled: false,
        region: new mapkit.CoordinateRegion(coordinate, span)
      })

      this.map.addAnnotation(this.createAnnotation(coordinate, null, true))

      // Show the button container after map is initialized
      this.buttonContainerTarget.classList.remove('hidden')
    } catch (error) {
      console.error('Error initializing map:', error)
      this.mapTarget.innerHTML = '<p>Failed to load the map.</p>'
    }
  }

  // Helper Methods
  get primaryCoordinate () {
    return new mapkit.Coordinate(this.latitudeValue, this.longitudeValue)
  }

  get isMaximized () {
    return this.element.classList.contains(this.openClasses[0])
  }

  get annotationCursor () {
    return this.isMaximized ? 'pointer' : 'default'
  }

  setMapInteractive (enabled) {
    if (!this.map) return
    this.map.isScrollEnabled = enabled
    this.map.isZoomEnabled = enabled
    this.map.showsZoomControl = enabled
  }

  getAnnotationData (place) {
    return {
      annotationId: place ? `nearby-${place.id}` : 'place-marker',
      markerType: place ? 'nearby-place' : 'primary'
    }
  }

  _getMarkerType (place) {
    // For nearby places, check their individual gastro status
    return place.isGastro ? MAP_MARKERS.GASTRO : MAP_MARKERS.DEFAULT
  }

  createAnnotation (coordinate, place = null, usePinMarker = false) {
    // Create custom annotation with our marker
    const markerElement = document.createElement('img')
    const markerStyle = `
      width: ${this.constructor.MARKER_WIDTH};
      height: ${this.constructor.MARKER_HEIGHT};
      filter: ${this.constructor.MARKER_DROP_SHADOW};
      cursor: ${this.annotationCursor};
      transition: filter 0.15s ease;
    `
    const pinStyle = `
      width: ${this.constructor.PIN_WIDTH};
      height: ${this.constructor.PIN_HEIGHT};
      filter: ${this.constructor.MARKER_DROP_SHADOW};
      cursor: ${this.annotationCursor};
      transition: filter 0.15s ease;
    `
    markerElement.style.cssText = usePinMarker ? pinStyle : markerStyle

    if (this.isMaximized) {
      markerElement.addEventListener('mouseenter', this.handleMarkerEnter)
      markerElement.addEventListener('mouseleave', this.handleMarkerLeave)
    }

    markerElement.src = usePinMarker
      ? MAP_MARKERS.PIN
      : this._getMarkerType(place)

    const annotationOptions = {
      animates: false,
      enabled: this.isMaximized,
      data: this.getAnnotationData(place),
      centerOffset: new DOMPoint(0, -16) // Half the height of the marker/pin
    }

    annotationOptions.callout = {
      calloutElementForAnnotation: () =>
        this.fetchCallout(place ? place.id : this.placeIdValue),
      calloutAnchorOffsetForAnnotation: (_, size) =>
        new DOMPoint(0, -(size.height + 40)),
      calloutShouldAnimateForAnnotation: () => false
    }

    return new mapkit.Annotation(
      coordinate,
      () => markerElement,
      annotationOptions
    )
  }

  fetchCallout (placeId) {
    const node = this.calloutTemplateTarget.content.firstElementChild.cloneNode(
      true
    )
    let url = node.dataset.url.replace('ID', placeId)

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        node.innerHTML = data.html
      })

    return node
  }

  escapeHandler () {
    if (this.isMaximized) this.minimizeMap()
  }

  maximizeMap () {
    this.element.classList.remove(...this.closedClasses)
    this.element.classList.add(...this.openClasses)
    this.maximizeButtonTarget.classList.add('hidden')
    this.minimizeButtonTarget.classList.remove('hidden')
    this.minimizeButtonTarget.classList.add('flex')
    this.showMoreContainerTarget.classList.remove('hidden')

    // save the current border radius from this.element.style
    this.savedBorderRadius = this.element.style.borderRadius
    // now get rid of any border radius for the maximized map
    this.element.style.borderRadius = 0

    // Give the DOM a chance to update before triggering map updates
    setTimeout(() => {
      if (this.map) {
        // Enable map interactions when expanded
        this.setMapInteractive(true)

        if (this.savedMaximizedRegion) {
          // Use saved region for subsequent maximizes
          this.map.setRegionAnimated(this.savedMaximizedRegion, false)
        }

        // Force map redraw by slightly adjusting the region
        const currentRegion = this.map.region
        const tempSpan = new mapkit.CoordinateSpan(
          currentRegion.span.latitudeDelta * 1.001,
          currentRegion.span.longitudeDelta * 1.001
        )
        const tempRegion = new mapkit.CoordinateRegion(
          currentRegion.center,
          tempSpan
        )
        this.map.setRegionAnimated(tempRegion, false)

        // After initial redraw, replace marker and load places
        setTimeout(() => {
          this.map.setRegionAnimated(currentRegion, false)

          // Show any hidden markers and add hover effects
          this.map.annotations.forEach(annotation => {
            annotation.enabled = true

            if (
              annotation.data.markerType === 'nearby-place' &&
              annotation.element
            ) {
              annotation.element.style.display = ''
              annotation.element.style.cursor = 'pointer'
              annotation.element.addEventListener(
                'mouseenter',
                this.handleMarkerEnter
              )
              annotation.element.addEventListener(
                'mouseleave',
                this.handleMarkerLeave
              )
            } else if (annotation.data.annotationId === 'place-marker') {
              annotation.element.style.cursor = 'pointer'
            }
          })

          // Only load places if we haven't loaded any yet
          if (this.pageValue === 1 && this.moreUrlValue) {
            fetch(this.moreUrlValue + '?page=1')
              .then(response => response.json())
              .then(data => {
                // Add markers for each place
                data.places.forEach(place => {
                  const coordinate = new mapkit.Coordinate(
                    place.latitude,
                    place.longitude
                  )
                  const annotation = this.createAnnotation(coordinate, place)
                  annotation.data.markerType = 'nearby-place'
                  this.map.addAnnotation(annotation)
                })

                this.pageValue++

                // Hide button if there are no more places
                if (!data.has_more) {
                  this.showMoreButtonTarget.classList.add('hidden')
                }

                // Only adjust zoom if necessary to fit all points
                const nearbyCoordinates = data.places.map(
                  place =>
                    new mapkit.Coordinate(place.latitude, place.longitude)
                )

                // Calculate required span to fit all points
                const requiredSpan = this.calculateZoomSpan(
                  [this.primaryCoordinate, ...nearbyCoordinates],
                  this.map.region.span
                )

                // Only adjust zoom if current view is too tight
                if (requiredSpan > this.map.region.span.latitudeDelta) {
                  const centerCoord = this.calculateCenter([
                    this.primaryCoordinate,
                    ...nearbyCoordinates
                  ])
                  const newRegion = new mapkit.CoordinateRegion(
                    centerCoord,
                    new mapkit.CoordinateSpan(requiredSpan, requiredSpan)
                  )
                  this.map.setRegionAnimated(newRegion)
                }

                if (!this.savedMaximizedRegion) {
                  // Save the region after first maximize
                  this.savedMaximizedRegion = this.map.region
                }
              })
              .then(() => {
                // Replace the primary marker with a star marker
                const primaryMarker = this.map.annotations.find(
                  annotation => annotation.data.annotationId === 'place-marker'
                )
                if (primaryMarker) {
                  const coordinate = primaryMarker.coordinate
                  this.map.removeAnnotation(primaryMarker)
                  this.map.addAnnotation(
                    this.createAnnotation(coordinate, null, true)
                  )
                }
              })
          } else {
            // Replace the primary marker with a star marker
            const primaryMarker = this.map.annotations.find(
              annotation => annotation.data.annotationId === 'place-marker'
            )
            if (primaryMarker) {
              const coordinate = primaryMarker.coordinate
              this.map.removeAnnotation(primaryMarker)
              this.map.addAnnotation(
                this.createAnnotation(coordinate, null, true)
              )
            }
          }
        }, 100)
      }
    }, 50)
  }

  minimizeMap () {
    if (!this.isMaximized) return

    // Save current region for next maximize
    this.savedMaximizedRegion = this.map.region

    // Remove maximized class
    this.element.classList.remove(...this.openClasses)
    this.element.classList.add(...this.closedClasses)
    this.minimizeButtonTarget.classList.remove('flex')
    this.minimizeButtonTarget.classList.add('hidden')
    this.maximizeButtonTarget.classList.remove('hidden')
    this.showMoreContainerTarget.classList.add('hidden')

    // Clean up any existing annotations and their event listeners
    this.map.annotations.forEach(annotation => {
      const element = annotation.element
      if (element) {
        element.removeEventListener('mouseenter', this.handleMarkerEnter)
        element.removeEventListener('mouseleave', this.handleMarkerLeave)
        element.style.cursor = 'default'
        if (annotation.data.markerType === 'nearby-place') {
          element.style.display = 'none'
        } else if (annotation.data.annotationId === 'place-marker') {
          element.src = MAP_MARKERS.PIN
        }
      }

      // Remove and re-add the annotation to reset its state, removing the callout
      this.map.removeAnnotation(annotation)
      // Re-add with enabled=false to prevent interaction
      annotation.enabled = false
      this.map.addAnnotation(annotation)
    })

    // restore the border radius from this.element.style
    this.element.style.borderRadius = this.savedBorderRadius

    // Give the DOM a chance to update before triggering map updates
    setTimeout(() => {
      if (this.map) {
        // Disable map interactions when minimized
        this.setMapInteractive(false)

        const centerRegion = new mapkit.CoordinateRegion(
          this.primaryCoordinate,
          this.initialSpan || new mapkit.CoordinateSpan(0.05, 0.05)
        )

        this.map.setRegionAnimated(centerRegion, false)
      }
    }, 50)
  }

  loadMorePlaces () {
    if (!this.moreUrlValue) return

    // Disable button while loading
    this.showMoreButtonTarget.disabled = true

    fetch(this.moreUrlValue + `?page=${this.pageValue}`)
      .then(response => response.json())
      .then(data => {
        // Add markers for each place
        data.places.forEach(place => {
          const coordinate = new mapkit.Coordinate(
            place.latitude,
            place.longitude
          )
          const annotation = this.createAnnotation(coordinate, place)
          annotation.data.markerType = 'nearby-place'
          this.map.addAnnotation(annotation)
        })

        this.pageValue++

        // Hide button if there are no more places, otherwise enable it
        if (!data.has_more) {
          this.showMoreButtonTarget.classList.add('hidden')
        } else {
          this.showMoreButtonTarget.disabled = false
        }

        // Only adjust zoom if we need to zoom out to fit all points
        const currentRegion = this.map.region
        const centerCoord = this.primaryCoordinate

        // Calculate required span to fit all points
        const distances = data.places.map(place => {
          const latDiff = Math.abs(centerCoord.latitude - place.latitude)
          const lngDiff = Math.abs(centerCoord.longitude - place.longitude)
          return Math.max(latDiff, lngDiff)
        })

        // Get the maximum distance and add padding
        const maxDistance = Math.max(...distances) * 1.2 // 20% padding
        const requiredSpan = maxDistance * 2

        // Only zoom out if required span is larger than current span
        if (requiredSpan > currentRegion.span.latitudeDelta) {
          const newSpan = new mapkit.CoordinateSpan(requiredSpan, requiredSpan)
          const newRegion = new mapkit.CoordinateRegion(centerCoord, newSpan)
          this.map.setRegionAnimated(newRegion)
        }
      })
      .catch(error => {
        console.error('Error loading more places:', error)
        this.showMoreButtonTarget.disabled = false
      })
  }

  // Zoom calculation helper
  calculateZoomSpan (coordinates, currentSpan) {
    const maxDistance = this.calculateMaxDistance(coordinates)

    // Adjust span based on the relative distance between points
    if (maxDistance < this.constructor.ZOOM_LEVELS.TIGHT.MAX_DISTANCE) {
      return new mapkit.CoordinateSpan(
        this.constructor.ZOOM_LEVELS.TIGHT.SPAN,
        this.constructor.ZOOM_LEVELS.TIGHT.SPAN
      )
    } else if (maxDistance < this.constructor.ZOOM_LEVELS.CLOSE.MAX_DISTANCE) {
      return new mapkit.CoordinateSpan(
        this.constructor.ZOOM_LEVELS.CLOSE.SPAN,
        this.constructor.ZOOM_LEVELS.CLOSE.SPAN
      )
    } else if (maxDistance < this.constructor.ZOOM_LEVELS.MEDIUM.MAX_DISTANCE) {
      return new mapkit.CoordinateSpan(
        this.constructor.ZOOM_LEVELS.MEDIUM.SPAN,
        this.constructor.ZOOM_LEVELS.MEDIUM.SPAN
      )
    } else {
      return new mapkit.CoordinateSpan(
        this.constructor.ZOOM_LEVELS.FAR.SPAN,
        this.constructor.ZOOM_LEVELS.FAR.SPAN
      )
    }
  }

  calculateMaxDistance (coordinates) {
    const centerCoord = this.primaryCoordinate
    const distances = coordinates.map(coord => {
      const latDiff = Math.abs(centerCoord.latitude - coord.latitude)
      const lngDiff = Math.abs(centerCoord.longitude - coord.longitude)
      return Math.max(latDiff, lngDiff)
    })
    return Math.max(...distances)
  }

  calculateCenter (coordinates) {
    const latitudes = coordinates.map(coord => coord.latitude)
    const longitudes = coordinates.map(coord => coord.longitude)

    const minLatitude = Math.min(...latitudes)
    const maxLatitude = Math.max(...latitudes)
    const minLongitude = Math.min(...longitudes)
    const maxLongitude = Math.max(...longitudes)

    const centerLatitude = (minLatitude + maxLatitude) / 2
    const centerLongitude = (minLongitude + maxLongitude) / 2

    return new mapkit.Coordinate(centerLatitude, centerLongitude)
  }

  handleMarkerEnter (event) {
    // on hover, the marker should brighten slightly
    event.target.style.filter =
      'drop-shadow(rgba(0, 0, 0, 0.3) 0px 1px 2px) brightness(1.2)'
  }

  handleMarkerLeave (event) {
    event.target.style.filter = 'drop-shadow(rgba(0, 0, 0, 0.3) 0px 1px 2px)'
  }
}
