import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source']
  static values = { name: String }

  async copy () {
    const text = this.sourceTarget.innerText || this.sourceTarget.value

    // must be in a secure context, so this won't work unless running SSL
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(text)

      this.dispatch('alert', {
        detail: {
          heading: 'Copied!',
          message: `${this.nameValue} copied to clipboard`,
          variant: 'success'
        },
        prefix: 'aon-event'
      })
    } else {
      console.error(
        'Unable to write to clipboard in this context (must be SSL)'
      )
    }
  }
}
