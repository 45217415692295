import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handleImageClick(event) {
    event.stopPropagation();

    this.dispatch("imageClick", {
      prefix: "imageCarousel",
    });
  }
}
